import { useState, useEffect } from "react";
const useIsSsr = () => {
  const [isServer, setIsServer] = useState(true);
  useEffect(() => {
    setIsServer(false);
  }, []);
  return isServer;
};
export {
  useIsSsr
};
